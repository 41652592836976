import './header.css'
import Header from './header';
import axios from "axios";
import './forfaits2.css';
import { useRef } from 'react';
import socleanlogo from './Pictures/thumbnail_SoClean_vitre_arriere_FINAL-120copie.png'
import React, { useState } from 'react';
export default function Forfaits4()
{

    const [piedvrValue, setPiedvrValue] = useState('');
    const [result1, setResult1] = useState(0);
    const [result2, setResult2] = useState(0);
  
    const handleInputChange = (event) => {
      const value = event.target.value;
      setPiedvrValue(value);
  
      // Effectuer le calcul mathématique ici
      setResult1(parseFloat(value) * 7.9);
      setResult2(parseFloat(value) * 20.47);
    };
  
  const bodyRef = useRef(null);
  const [showModal, setShowModal] = React.useState(false);
  const [nameError, setNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");

  const onClick = (event) => {
    const value = event.target.value;
    sessionStorage.setItem("forfaitnom",value);
    setEmailError("");
    setNameError("");
    setShowModal(!showModal);};

    function InputNameChange(event) {
      const name = event.target.value;
      if (name.length < 2)
      {setNameError("Le prénom/nom doit comprendre au moins 2 caractères");}
      else if(/\d/.test(name)) 
      {setNameError("Le prénom/nom ne doit comprendre que des caracteres alphabetique");}
      else if (!/^[a-zA-Z '-]+$/.test(name)) 
      {setNameError("Le prénom/nom ne peux pas contenir de caracteres speciaux");}
      else
      {setNameError("");}
    }
  
    function InputEmailChange(event) {
      const email = event.target.value;
      if (email.length < 5)
      {setEmailError("L'adresse courriel doit comprendre au moins 5 caracteres");}
      else if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) 
      {setEmailError("L'adresse courriel entrée n'est pas dans un format valide");}
      else
      {setEmailError("");}
    }

    function SendRequestForfait()
    {
      const firstname = document.getElementById("firstnameforfait").value;
      const lastname = document.getElementById("lastnameforfait").value;
      const email = document.getElementById("emailforfait").value;
      const tel = document.getElementById("telephoneforfait").value;
      const marque = document.getElementById("marquevehicule").value;
      const modele = document.getElementById("modelevehicule").value;
      const dispoforfait = document.getElementById("dispoforfait").value;
      const nomforfait = sessionStorage.getItem("forfaitnom");
  
      const data = {
        firstname: firstname,
        email: email,
        lastname: lastname,
        tel:tel,
        marque:marque,
        modele:modele,
        dispoforfait:dispoforfait,
        nomforfait:nomforfait
      };
  
      axios.post("https://dev.socleanesthetique.com:3001/newrequestforfait", data).then(function (response) {
            document.getElementById("firstnameforfait").value = "";
            document.getElementById("lastnameforfait").value = "";
            document.getElementById("emailforfait").value = "";
            document.getElementById("telephoneforfait").value = "";
            document.getElementById("marquevehicule").value = "";
            document.getElementById("modelevehicule").value = "";
            document.getElementById("dispoforfait").value = "";
            sessionStorage.clear();
            setShowModal(!showModal);
            }).catch(function (error)
            {
              document.getElementById("firstnameforfait").value = "";
              document.getElementById("lastnameforfait").value = "";
              document.getElementById("emailforfait").value = "";
              document.getElementById("telephoneforfait").value = "";
              document.getElementById("marquevehicule").value = "";
              document.getElementById("modelevehicule").value = "";
              document.getElementById("dispoforfait").value = "";
              sessionStorage.clear();
              setShowModal(!showModal);
              });;
    }

    const OnClickEvent = () => {
      
      let longueur = document.getElementById("piedvr").value;
  };
    return(
      <div>
        <Header></Header>
  <div class="bg-white dark:bg-gray-800 pt-20 " id="pricing_other">
  <div class="relative mx-auto max-w-7xl px-6 text-center lg:px-8">
    <div class="mx-auto max-w-2xl lg:max-w-4xl">
      <p class="mt-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-200">Trouve le parfait forfait pour ton véhicule récréatif</p>
      <br></br>
      <small class="text-s font-semibold leading-8 text-gray-900 dark:text-gray-200">Prix sujet à changement selon votre véhicule récréatif</small>
      <br></br>
      <small class="text-s font-semibold leading-8 text-gray-900 dark:text-gray-200">Longueur (en pied) de votre véhicule récréatif : </small>
      <input
        type="number"
        value={piedvrValue}
        onChange={handleInputChange}
        placeholder="--"
        min={0}
      />
    </div>
  </div>
  <div class="mx-auto max-w-7xl px-6 lg:px-8 mb-6">
  </div>
  <div class="">
    <div class="relative z-10 mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-7xl lg:grid-cols-3 lg:gap-8">
        <div class="flex flex-col rounded-3xl bg-white dark:bg-gray-900 shadow-xl ring-1 ring-black/10">
          <div class="p-8 sm:p-10">
            <div class="flex">
              <h3 class="text-lg font-semibold leading-8 tracking-tight text-gray-900 dark:text-gray-200">Lavage
              </h3>
              <div class="items-center ml-2">
              </div>
            </div>
            <div class="mt-4 flex items-baseline text-5xl tracking-tight text-gray-900 dark:text-gray-200 font-semibold">
              {result1.toFixed(2)}$</div>
              <p class="mt-4 text-base leading-7 text-gray-600 dark:text-gray-300">• Prélavage au canon à mousse</p>
              <p class="mt-1 text-base leading-7 text-gray-600 dark:text-gray-300">• Lavage à la main</p>
              <p class="mt-1 text-base leading-7 text-gray-600 dark:text-gray-300">• Entretien des jantes en profondeur</p>
              <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Dégraissage des pneus</p>
              <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Ajout de lustrant protecteur sur les pneus</p>
              <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Application de lustrant protecteur sur les plastiques extérieures</p>
              <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Séchage du véhicule</p>
              <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Nettoyage des vitres extérieur</p>
              <p class="mt-1 text-base leading-7 text-gray-600 dark:text-gray-300">• Rinçage des toiles (si applicable)</p>
              <p class="mt-1 text-base leading-7 text-gray-600 dark:text-gray-300">• Application d’une protection auto-perlante sur le pare-brise avant</p>
              <p class="mt-1 text-base leading-7 text-gray-600 dark:text-gray-300">• Nettoyage et traitement anti rayons UV sur le toit (<strong>+69.99$</strong>)</p>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
          </div>
          <div class="flex flex-1 flex-col p-2">
          <button class="bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" value={"Lavage VR"} onClick={onClick}>Choisir ce forfait</button>
          </div>
        </div>


        <div class="flex flex-col rounded-3xl bg-white dark:bg-gray-900 shadow-xl ring-1 ring-black/10">
          <div class="p-8 sm:p-10">
            <div class="flex">
              <h3 class="text-lg font-semibold leading-8 tracking-tight text-gray-900 dark:text-gray-200" id="tier-plus">Lavage + cirage
              </h3>
              <div class="items-center ml-2">
              </div>
            </div>
            <div class="mt-4 flex items-baseline text-5xl tracking-tight text-gray-900 dark:text-gray-200 font-semibold">
            {result2.toFixed(2)}$</div>
            <p class="mt-4 text-base leading-7 text-gray-600 dark:text-gray-300">• Prélavage au canon à mousse</p>
              <p class="mt-1 text-base leading-7 text-gray-600 dark:text-gray-300">• Lavage à la main</p>
              <p class="mt-1 text-base leading-7 text-gray-600 dark:text-gray-300">• Entretien des jantes en profondeur</p>
              <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Dégraissage des pneus</p>
              <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Ajout de lustrant protecteur sur les pneus</p>
              <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Application de lustrant protecteur sur les plastiques extérieures</p>
              <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Séchage du véhicule</p>
              <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Nettoyage des vitres extérieur</p>
              <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Rinçage des toiles (si applicable)</p>
              <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Application d’une protection auto-perlante sur le pare-brise avant</p>
              <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Application d'une protection (cire) sur la peinture</p>
              <p class="mt-1 text-base leading-7 text-gray-600 dark:text-gray-300">• Nettoyage et traitement anti rayons UV sur le toit (<strong>+69.99$</strong>)</p>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
          </div>
          <div class="flex flex-1 flex-col p-2">
          <button class="bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" value={"Lavage et cirage VR"} onClick={onClick}>Choisir ce forfait</button>
          </div>
        </div>

        <div class="flex flex-col rounded-3xl bg-white dark:bg-gray-900 shadow-xl ring-1 ring-black/10">
          <div class="p-8 sm:p-10">
            <div class="flex">
              <h3 class="text-lg font-semibold leading-8 tracking-tight text-gray-900 dark:text-gray-200" id="tier-plus">Polissage et remise à neuf
              </h3>
              <div class="items-center ml-2">
              </div>
            </div>
            <div class="mt-4 flex items-baseline text-5xl tracking-tight text-gray-900 dark:text-gray-200 font-semibold">
            Sur demande</div>
            <p class="mt-4 text-base leading-7 text-gray-600 dark:text-gray-300">• Prélavage au canon à mousse</p>
              <p class="mt-1 text-base leading-7 text-gray-600 dark:text-gray-300">• Lavage à la main</p>
              <p class="mt-1 text-base leading-7 text-gray-600 dark:text-gray-300">• Entretien des jantes en profondeur</p>
              <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Dégraissage des pneus</p>
              <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Ajout de lustrant protecteur sur les pneus</p>
              <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Application de lustrant protecteur sur les plastiques extérieures</p>
              <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Séchage du véhicule</p>
              <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Nettoyage des vitres extérieur</p>
              <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Rinçage des toiles (si applicable)</p>
              <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Application d’une protection auto-perlante sur le pare-brise avant</p>
              <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Application d'une protection (cire) sur la peinture</p>
              <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• LAVAGE EXTRÊME</p>
              <p class="text-base leading-7 text-gray-600 dark:text-gray-300">• Nettoyage et traitement anti rayons UV sur le toit (<strong>+69.99$</strong>)</p>
              <br></br>
              <br></br>
              <br></br>
          </div>
          <div class="flex flex-1 flex-col p-2">
          <button class="bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" value={"Polissage et remise à neuf VR"} onClick={onClick}>Choisir ce forfait</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{showModal && <div id="background_modal_blur">
        <div id="background_modal">
        <form class="newsletter_subscription">
        <div class="form_newsletter">
        <button class="CloseModal" onClick={onClick}>
            X
          </button>
          <img
              class="logo_modal"
              src={socleanlogo}
            ></img>
            <br/>
          <h3 class="text_newsletter_promotional">
          Nous sommes ravis que vous ayez opté pour nos services ! Veuillez renseigner les champs ci-dessous pour finaliser la demande
          </h3>
          <br/>
          <p class="text-white">Renseignements personnels</p>
          <div className="form_newsletter_name">
            <input
            id = "firstnameforfait"
              onChange={InputNameChange}
              class="inputInscriptionName"
              type="text"
              placeholder="Prénom"
            />
            <input
              onChange={InputNameChange}
              id = "lastnameforfait"
              class="inputInscriptionName"
              type="text"
              placeholder="Nom"
            />
          </div>
          {<span id="firstname-validation-message">{nameError}</span>}
          <div className="form_newsletter_name">
          <input
            onChange={InputEmailChange}
            id = "emailforfait"
            class="inputInscription"
            type="text"
            placeholder="Courriel"
          />
          <input
            id = "telephoneforfait"
            class="inputInscription"
            type="text"
            placeholder="Téléphone"
          />
          </div>
          <br></br>
          <p class="text-white">Renseignements sur le véhicule</p>
          <div className="form_newsletter_name">
          <input
            id = "marquevehicule"
            class="inputInscription"
            type="text"
            placeholder="Marque"
          />
          <input
            id = "modelevehicule"
            class="inputInscription"
            type="text"
            placeholder="Modèle"
          />
          </div>
          <br></br>
          <p class="text-white">Renseignements sur vos disponibilité</p>
          <textarea
            id = "dispoforfait"
            class="min-w-12"
            rows="3"
            cols="35"
            placeholder="Disponibilité et commentaires"
          />
          <br></br>
          {<span id="email-validation-message">{emailError}</span>}
          <button type="button" class="btnInscription" onClick={SendRequestForfait}> Envoyer ma demande</button>
        </div>
      </form>
        </div>
      </div>}
</div>
)
}